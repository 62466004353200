@import "../../App.variables.scss";
@import "../../AppHelpers.module.scss";

.popover {
  @include flex-center;
  position: fixed;
  span {
    background-color: black;
    color: white;
    padding: 10px;
    text-align: center;
    // height: 25px;
    position: relative;
    display: block;
    width: fit-content;

    &:before {
      content: "";
      position: absolute;
      bottom: -12px;
      left: calc(50% - 12px);
      width: 0px;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid black;
      border-bottom: 0px solid;
      z-index: 4;
    }
  }
}
