@import "../App.variables.scss";
@import "../AppHelpers.module.scss";

.navbar {
  height: calc(100% - 128px);
  position: relative;
  .line {
    height: calc(100% + 20px);
    position: absolute;
    width: 1px;
    left: 99px;
    z-index: -1;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%' fill='none' stroke='white' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

    &:before {
      content: "";
      width: 7px;
      height: 7px;
      background-color: white;
      position: absolute;
      border-radius: 50%;
      left: -3px;
      top: -7px;
    }
  }
  .list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 10px 0;
    margin: 0;
    gap: 15px;

    .item {
      transition: all ease-in-out 400ms;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: $navigation-font-size;
      line-height: 1;
      text-decoration: none;
      @include stroked-text;

      &.active {
        flex-grow: 1;
        color: white;
        stroke: none;
        -webkit-text-stroke: 0;
      }

      .itemText {
        background-color: $background-color;
      }
    }
  }
}
