@import "../App.variables.scss";

.aboutMe {
  display: flex;
  align-items: center;
  justify-content: center;

  .largeHeader {
    margin: 0;
    font-size: 52px;
  }

  .intro {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .avatar {
      width: 310px;
    }

    .introText {
      width: 530px;
    }
    .xitLogo {
      width: 150px;
      margin-bottom: -6px;
    }
  }
}

@media (max-width: $tablet-max-width) {
  .aboutMe {
    .largeHeader {
      font-size: 46px;
    }
  }
}

@media (max-width: $mobile-max-width) {
  .aboutMe {
    .intro {
      flex-direction: column;
      align-items: center;

      .introText {
        width: unset;
        text-align: center;
      }
    }
    .avatar {
      transform: scale(0.8);
    }
    .largeHeader {
      font-size: 36px;
    }
  }
}
