@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400&display=swap");

body {
  background-color: #303030;
  color: #e0e0e0;
  height: 100%;
  .logo {
    path {
      fill: #e0e0e0;
    }

    circle {
      fill: #e47a2d;
    }
  }

  .text-accent {
    color: #e47a2d;
  }

  #root > div {
    display: flex;
  }

  section {
    overflow: hidden;
  }
}
