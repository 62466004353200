////Colors

$background-color: #303030;
$primary-color: #e47a2d;
$secondary-color: #fff;
$background-text: #686868;

////Fonts

$logo-font-size: 72px;
$navigation-font-size: 24px;
$base-font-size: 16px;

////Breakpoints
$tablet-max-width: 1024px;
$mobile-max-width: 760px;
    