@import "../App.variables.scss";
@import "../AppHelpers.module.scss";

.skills {
  .skillGroupContainer {
    height: 100%;
    @include flex-center;
    overflow: auto;
    .skillGroupGrid {
      display: grid;
      padding: 25px;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      min-height: 300px;
      //height: 300px;
      // transition: all 400ms ease-in-out;
      // // min-width: 900px;

      // &.active {
      //   grid-template-columns: 1fr 0fr;
      //   height: calc(100% - 100px);

      //   .skillGroup:not(.active) {
      //     display: none;
      //   }
      // }
      .skillGroup {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%' fill='none' stroke='white' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        padding: 10px;
        position: relative;
        cursor: pointer;

        &:hover {
          .skillGroupTitle {
            .skillGroupTitleText {
              color: white;
            }
          }
        }

        .skillGroupTitle {
          position: absolute;
          width: 100%;
          height: auto;
          text-align: center;
          top: -18px;
          left: 0;
          font-size: 24px;

          .skillGroupTitleText {
            text-transform: uppercase;
            background-color: $background-color;
            padding: 0 8px;
            @include stroked-text;
            transition: color 400ms ease-in-out;
          }
        }

        .skillLogosContainer {
          height: calc(100% - 18px);
          gap: 30px;
          padding: 10px;
          @include flex-center;
          flex-wrap: wrap;

          .skillLogo {
            width: 65px;
          }
        }
      }
    }
  }
  .skillRow {
    display: flex;
    height: 70px;

    .skillBar {
      width: calc(100% - 65px);
      position: relative;
      display: flex;
      align-items: center;

      .skillValue {
        content: "";
        position: absolute;
        height: 30%;
        background-color: #e2e1e1;
      }
    }
  }
}

@media (max-width: $mobile-max-width) {
  .skills {
    .skillGroupContainer {
      align-items: flex-start;
      .skillGroupGrid {
        grid-template-columns: 1fr;
      }
    }
  }
}
