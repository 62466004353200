@import "../App.variables.scss";

.sidebar {
  width: 200px;
  height: 100%;
  text-align: center;

  .logo {
    font-size: $logo-font-size;
    font-weight: 700;
    span {
      color: $primary-color;
    }
  }
}

@media (max-width: $tablet-max-width) {
  .sidebar {
    display: none;
  }
}
