@import "App.variables.scss";

html {
  font-family: "Poppins", sans-serif;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &,
  body,
  #root,
  section,
  #root > div {
    height: 100%;
    margin: 0;
  }
}
