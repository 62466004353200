@import "App.variables.scss";

.absolute {
  position: absolute;
}
.backgroundTextColor {
  color: $background-text;
}
.accentTextColor {
  color: $primary-color;
}

// MIXINS

@mixin stroked-text {
  stroke: $secondary-color;
  stroke-width: 1px;
  color: $background-color;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $secondary-color;
  font-weight: 700;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
