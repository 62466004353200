@import "../App.variables.scss";

.pageOutlet {
  width: calc(100% - 200px);
  overflow: hidden;

  .scroller {
    transition: all 400ms ease-in-out;

    & > * {
      width: 100%;
      height: 100vh;
    }
  }
}

@media (max-width: $tablet-max-width) {
  .pageOutlet {
    width: 100%;
  }
}
